:root {
  --backgroundColor: #f7f7f7;
  --primaryColor: #1a1a1a;
  --textColorSecondary: rgba(0, 0, 0, 0.45);

  --Black: #15120f;
  --Gold: #e0ae15;
  --White: #fff;
  --Dark-Grey: #393837;
  --Grey: #808a9f;

  --overlay: rgba(32, 27, 23, 0.7);
  --Light-grey: #e7e7e7;
}
